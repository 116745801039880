<template>
  <section class="page-content">
    <h1>Personvernerklæring</h1>
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
      <p class="subtitle">
        Hytta.io tar ditt personvern på alvor og er forpliktet til å beskytte dine personopplysninger 
        i samsvar med den europeiske personvernforordningen (GDPR). Denne personvernerklæringen 
        forklarer hvordan vi samler inn, bruker, lagrer og beskytter dine personopplysninger når du 
        bruker vår app.
      </p>
      </column>
    </row>
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
        <h3>1. Innsamling av personopplysninger</h3>
        <p>Vi samler inn opplysninger som navn, e-postadresse, telefonnummer og brukerinformasjon for å opprette og administrere din profil. 
          Dette er nødvendig for å tilby våre tjenester og sikre en god brukeropplevelse.</p>
        <h3>2. Bruk av personopplysninger</h3>
        <p>Vi bruker dine personopplysninger til følgende formål:</p>
        <ul>
          <li>Administrere og forbedre appens funksjoner.</li>
          <li>Håndtere bookingforespørsler og sjekklister.</li>
          <li>Kommunisere med deg vedrørende din bruk av appen.</li>
          <li>Dele opplevelser og informasjon i den digitale hytteboka med andre brukere.</li>
        </ul>
        <h3>3. Deling av personopplysninger</h3>
        <p>Vi deler ikke dine personopplysninger med tredjeparter uten ditt samtykke, 
        med mindre det er nødvendig for å oppfylle våre juridiske forpliktelser eller beskytte våre rettigheter.</p>
        <h3>4. Lagring og sikkerhet</h3>
        <p>Dine personopplysninger lagres på sikre servere, og vi iverksetter passende tekniske og organisatoriske tiltak for å beskytte dem mot uautorisert tilgang, 
        tap eller ødeleggelse.</p>
        <h3>5. Dine rettigheter</h3>
        <p>Du har rett til å få innsyn i, korrigere, slette eller begrense behandlingen av dine personopplysninger. Du kan også trekke tilbake ditt samtykke til 
        behandlingen av dine personopplysninger når som helst. For å utøve disse rettighetene, vennligst kontakt oss på support@hytta.io.</p>
        <h3>6. Endringer i personvernerklæringen</h3>
        <p>Vi kan oppdatere denne personvernerklæringen fra tid til annen. Eventuelle endringer vil bli publisert på vår nettside og varslet via appen.</p>
        <p>Hvis du har spørsmål om denne personvernerklæringen eller vår behandling av dine personopplysninger, eller om du ønsker at vi sletter din informasjon fra våre databaser 
          vennligst kontakt oss på support@hytta.io.</p>
        <p><strong>Fernapps AS
        Kvernbakken 21, 4355 Kverneland, Norge</strong>
        E-post: <a href="mailto:support@hytta.io">support@hytta.io</a></p>
      </column>
    </row>
  </section>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
  .feature-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: $light-grey;
    border-radius: 14px;
    padding: 1.5rem;
    img {
      width: 50%;
      margin-bottom: 1rem;
    }
    h2 {
      font-weight: 500;
    }
  }
</style>
